body {
  /* BODY */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  margin: 0;
  letter-spacing: 0.05em;

  /* Primary Text */

  color: #FFFFFF;
}
  
ol, ul {
    padding-left: 30px;
}


#awwwards {
    top: 100vh;
    transform: translateY(-140%);
}

.historyContainer::-webkit-scrollbar {
  width: 12px;
  scroll-margin-right: 10px;
}

.historyContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border: 1.5px solid #C6C8CA;
  border-radius: 3px;
  scroll-margin-right: 10px;
}

.historyContainer::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 2px solid #C6C8CA;
  border-radius: 3px;
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header { 
  border-bottom: 0;
  padding: 5px;
  left: -9px; 
  top: -9px; 
  right: -9px; 
  z-index: 999; 
  display: flex;
  flex-direction: row;
}

.header .logo { display: inline-block; width: 106px; height: 106px; position: fixed; left: 9px; top: 0; overflow: hidden; opacity: 1; transition: opacity .4s ease; }
.header .logo svg { width: 133px; max-width: none; height: 134px; position: absolute; right: 0; bottom: 0; }

.header-logo {
  flex-grow: 1;
}
.menu-buttons {
  display: flex;
  flex-grow: 5;
  align-items: flex-end;
  flex-direction: column;
}

/* GAMES */
.andreas-symbol {
    display: inline-block;
    font-size: 4vw;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: clip;
    text-overflow: clip;
}
  
.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}
/* HEADER */
.header {
  background: #000000;
}

/* ROBOTS */
html, body, #root, #app>div {
  height: 100%
}
.chat {
  /* Chat box */
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  background: #000000;
  border: 1px solid #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.historyContainer{
  flex-grow: 10;
  height: 80%;
  overflow-y: auto;
  margin-right: 10px;
  flex-direction: column-reverse;
}

.audioRecorder {
  border: 20px;
  margin: 10px 20px 10px 10px;

}


@media screen and (max-width: 480px) {
  .chatInput {
    height: 100px;
  
    min-height: 100px;
  }
  
}

@media screen and (max-width: 480px) {
  .chatContainer {
  
    flex-grow: 1;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  
}

@media screen and (width > 480px) {
  .chatContainer {
  
    flex-grow: 1;
    height: fit-content;
    display: flex;
  }
  
}

.record {
  margin: auto;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: fit-content;
}


button {
    /* TextFrame */


    box-sizing: border-box;

    /* Auto layout */
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 7px 30px;
  
    width: 143px;
    height: 50px;
  
    /* Primaries/Primary Text */
  
    background: #FFFFFF;
    border: 1px solid #C6C8CA;
    /* Buttons/ButtonPopping */
  
    box-shadow: 3px 3px 0px 4px rgba(255, 255, 255, 0.25);
    border-radius: 3px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
}

button {
  /* Primary Text/Heading 4 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;

  /* identical to box height, or 36px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary BG */

  color: #000000;
}

.button-textbox {
  /* TextFrame */


  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 30px;

  width: fit-content;
  height: 50px;

  /* Primaries/Primary Text */

  background: #FFFFFF;
  border: 1px solid #C6C8CA;
  /* Buttons/ButtonPopping */

  box-shadow: 3px 3px 0px 4px rgba(255, 255, 255, 0.25);
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  user-select: none;
}


.button-textbox:active {
  /* TextFrame */


  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 30px;

  width: fit-content;
  height: 50px;

  /* Primaries/Primary Text */

  background: #FFFFFF;
  /* Buttons/ButtonPressed

  Makes it look like you pressed in
  */
  box-shadow: inset 2px 3px 0px rgba(0, 0, 0, 0.75);
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.button-textbox h2 {
  /* Button */

  width: fit-content;
  height: fit-content;
  margin: 0;
  /* Primary Text/Heading 4 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  /* identical to box height, or 36px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary BG */



  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

#chatBox {
    /* Auto layout */
    margin: 5px 10px 10px 20px;
    padding: 5px 10px;

    flex-grow: 2;
    width: 80%;
    color: #fff;
    background: #000000;
    border-radius: 3px;

}

#chatBox::placeholder {
  font-weight: bold;
  opacity: 0.9;
  color: #A3A8AD;
}


.bodyContainer {
  display: flex;
  flex-direction: row;
  height: inherit;

}

.sideBar {
  background-color: #000000;
  padding: 5px;
}

.USER {
  /* Chat entry */
  box-sizing: border-box;


  text-align: left;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 5px 100px 10px 5px;
  padding: 5px 10px;
  gap: 10px;

  border: 1px dashed #A3A8AD;
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.Code {
  /* Chat entry */

  box-sizing: border-box;

  text-align: right;
  border: 1px dashed #A3A8AD;
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}


.AI {
  /* Chat entry */
  box-sizing: border-box;

  text-align: right;
  margin: 5px 5px 5px 100px;
  padding: 5px 10px;
  gap: 10px;

  border: 1px dashed #A3A8AD;
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

@media screen and (max-width: 480px) {
  .record{
    height: 100px;
  }

  .button-textbox {
    height: 100px;
  }

  .button-textbox > h2 {
    height: 100%;
  }
  
}


@media screen and (max-width: 720px) {

  .Code {
    display: none;
  }

  .sideBar {
    display: none;
  }
}